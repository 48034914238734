var EMA = EMA || {};

EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content

            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: -100,
                success: function(element) {
                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 100);
                }
            });

            return bLazy;

        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        },
        parallax: function(image, speed) {
            if (Foundation.MediaQuery.atLeast('medium')) {
                image.css('transform', 'translate3D(0, ' + (EMA.BUILD.resize().scrollTop * speed) + 'px, 0)');
            }
        }
    };
})();




EMA.HOME = (function() {
    return {
        init: function() {
            var self = this;
            // Custom Scripts for Pelican Template //

            console.log("HOME");
            jQuery(window).on('scroll', function () {
                EMA.HOME.animateElement();
            });
            jQuery('.more-posts').on("click", function () {
                jQuery('.blog-holder').find(".hidden").slice(0, 4).removeClass("hidden").addClass("animate loaded");
                EMA.HOME.animateElement();
                if (!jQuery('.blog-holder').find(".hidden").length)
                {
                    jQuery('.more-posts').hide();
                    jQuery('.no-more-posts').css('display', 'inline-block');
                }
                EMA.HOME.configGrid();
            });

            EMA.HOME.configGrid();

            jQuery(".single-portfolio .content-wrapper").first().css("padding-top", 0);

            if (!jQuery(".single-portfolio .nav-next").length)
            {
                jQuery(".single-portfolio .nav-previous").css("padding-bottom", 0);
            }

            //Portfolio
            var grid = jQuery('.grid').imagesLoaded(function () {
                grid.isotope({
                    itemSelector: '.grid-item',
                    transitionDuration: 0,
                    masonry: {
                        columnWidth: '.grid-sizer'
                    }
                });
                jQuery('.grid-sizer, .grid-item').innerWidth(jQuery(".grid-sizer, .grid-item").innerWidth());
            });

            //Placeholder show/hide
            jQuery('input, textarea').focus(function () {
                jQuery(this).data('placeholder', jQuery(this).attr('placeholder'));
                jQuery(this).attr('placeholder', '');
            });
            jQuery('input, textarea').blur(function () {
                jQuery(this).attr('placeholder', jQuery(this).data('placeholder'));
            });

            singlePaginationHeightFix();

            //Set menu
            jQuery('.main-menu').smartmenus({
                subMenusSubOffsetX: 1,
                subMenusSubOffsetY: -8,
                markCurrentItem: true
            });

            jQuery(".site-content").fitVids();



        },
        configGrid: function() {
          jQuery('.blog-item-holder.has-post-thumbnail:nth-child(2n)').each(function () {
              var x = jQuery(".blog-holder").width() - jQuery(this).find('.post-thumbnail').width() - (parseFloat(jQuery(this).find('.post-thumbnail').css('max-width')) - jQuery(this).find('.post-thumbnail').width()) / 2;
              jQuery(this).find('.post-thumbnail').css('transform', 'translateX(' + x + 'px)');
              jQuery(this).addClass("is-right");

              x = jQuery(this).find('.entry-holder').innerWidth() - 87 + jQuery(this).find('.post-thumbnail').width() - x;
              jQuery(this).find('.entry-holder').css('transform', 'translateX(-' + x + 'px)');
          });
        },
        animateElement: function() {
          jQuery(".animate").each(function (i) {

              var top_of_object = jQuery(this).offset().top;
              var bottom_of_window = jQuery(window).scrollTop() + jQuery(window).height();
              if ((bottom_of_window) > top_of_object) {
                  jQuery(this).addClass('show-it');
              }

          });
        }

      }
})();

$(function() {
    // Call when document is ready
    EMA.BUILD.init();
});
